@import 'settings/variables';

$hotspot-content__shadow-colour: $c-black;
$hotspot-content__shadow-opacity: 0.15;
$hotspot__animation: true;

@import 'flagmaps/resources/sass/hotspots';

.hotspot--stockitem {
    .hotspot__content {
        padding: 24px;
        max-width: 200px;

        article {
            margin-bottom: 0;
        }
    }
}
