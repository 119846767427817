@use 'sass:math';

$hotspot-content__background-color: #fff !default;
$hotspot-content__text-color: #000 !default;
$hotspot-content__h-padding: 10px !default;
$hotspot-content__v-padding: 8px !default;
$hotspot-content__max-width: 250px !default;
$hotspot-content__border-radius: 4px !default;
$hotspot-content__arrow-size: 10px !default;
$hotspot-content__has-shadow: true !default;
$hotspot-content__shadow-colour: #000 !default;
$hotspot-content__shadow-opacity: 0.5 !default;
$hotspot-content__shadow-xoffset: 0 !default;
$hotspot-content__shadow-yoffset: 0 !default;
$hotspot-content__shadow-blur: 5px !default;
$hotspot-content__z-index: 10 !default;

$hotspot__svg-fill: #fff !default;
$hotspot__svg-size: 32px !default;

$hotspot__animation: false !default;
$hotspot__animation_name: pulse !default;
$hotspot__animation_duration: 2s !default;

$hotspot__animation_pulse_scale: 1.1 !default;
$hotspot__animation_pulse_color: #fff !default;

.image-with-hotspots {
    position: relative;

    img {
        width: 100%;
        height: auto;
    }
}
    .hotspots {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $hotspot-content__z-index;
    }

    .hotspot {
        position: absolute;
        list-style: none;
    }

    .hotspot__button {
        display: inline-block;
        padding: 0;
        background-color: transparent;
        position: relative;

        svg {
            position: absolute;

            top: 0;
            left: 0;
            transform: translate(-50%, -50%);

            fill: $hotspot__svg-fill;
            width: $hotspot__svg-size;
            height: $hotspot__svg-size;
        }

        @if $hotspot__animation {
            svg {
                animation-name: $hotspot__animation_name;
                animation-duration: $hotspot__animation_duration;
                animation-iteration-count: infinite;
            }

            &:has(+ .hotspot__content--active),
            &:hover {
                svg {
                    animation: none;
                    transform: translate(-50%, -50%) scale($hotspot__animation_pulse_scale);
                    transition: transform $hotspot__animation_duration;
                }
            }
        }

        cursor: pointer;
    }

    .hotspot__content-wrap {
        display: none;

        position: absolute;
        top: 0;
        left: 0;
        z-index: $hotspot-content__z-index;

        @if $hotspot-content__has-shadow {
            filter: drop-shadow(
                $hotspot-content__shadow-xoffset
                $hotspot-content__shadow-yoffset
                $hotspot-content__shadow-blur
                rgba($hotspot-content__shadow-colour, $hotspot-content__shadow-opacity)
            );
        }
    }

    .hotspot__content {
        background-color: $hotspot-content__background-color;
        color: $hotspot-content__text-color;

        padding: $hotspot-content__v-padding $hotspot-content__h-padding;

        border-radius: $hotspot-content__border-radius;
        max-width: $hotspot-content__max-width;
        width: max-content;
    }

    .hotspot__arrow {
        position: absolute;

        width: $hotspot-content__arrow-size;
        height: $hotspot-content__arrow-size;

        background-color: $hotspot-content__background-color;

        $shift: math.div($hotspot-content__arrow-size, 2) * -1;

        .hotspot__content-position--bottom &,
        .hotspot__content-position--bottom-start &,
        .hotspot__content-position--bottom-end & {
            top: $shift;
            transform: rotate(45deg);
        }

        .hotspot__content-position--top &,
        .hotspot__content-position--top-start &,
        .hotspot__content-position--top-end & {
            bottom: $shift;
            transform: rotate(225deg);
        }

        .hotspot__content-position--right &,
        .hotspot__content-position--right-start &,
        .hotspot__content-position--right-end & {
            left: $shift;
            transform: rotate(-45deg);
        }

        .hotspot__content-position--left &,
        .hotspot__content-position--left-start &,
        .hotspot__content-position--left-end & {
            right: $shift;
            transform: rotate(135deg);
        }
    }

@keyframes pulse {
    0% {
        filter: drop-shadow(0 0 0 $hotspot__animation_pulse_color);
        transform: translate(-50%, -50%) scale(1);
    }

    30% {
        transform: translate(-50%, -50%) scale($hotspot__animation_pulse_scale);
    }

    60% {
        filter: drop-shadow(0 0 10px $hotspot__animation_pulse_color);
    }
}
